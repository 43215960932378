<template>
  <div id="rmbAccount">
    <header-nav :content="`${$t('pages_router_021')} / ${ $t('pages_router_053')}`" />
    <div class="common-card-body">
      <a-tabs v-model:activeKey="activeKey" class="header-tabs">
        <template #rightExtra>
          <a-button
            v-if="activeKey === 'detail'"
            type="primary"
            ghost
            style="min-width: 100px"
            :loading="exportLoading"
            @click="handleExport"
          >
            <template #icon><download-outlined /></template>
            {{ exportLoading ? $t('common_text_023') : $t('common_text_033') }}
          </a-button>
        </template>
        <a-tab-pane key="list" :tab="$t('pages_pay_343')">
          <div class="table-part">
            <a-table
              :scroll="{ x: 800 }"
              :loading="loading"
              :pagination="false"
              :custom-header-row="() => { return {class: 'my-table-tr'}}"
              :columns="columnsL"
              :data-source="acctList"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'edit'">
                  <a-space style="width: 100%; justify-content: center">
                    <a-button type="link" size="small" @click="$router.push({ path: '/funds/rmb/pay_single', query: { accountNo: record.accountNo }})">{{ $t('pages_pay_344') }}</a-button>
                    <a-divider type="vertical" style="margin: 0" />
                    <a-button type="link" size="small" @click="$router.push({ path: '/funds/rmb/cash_single', query: { accountNo: record.accountNo, balance: record.balance }})">{{ $t('pages_pay_345') }}</a-button>
                    <a-divider type="vertical" style="margin: 0" />
                    <a-tooltip>
                      <template #title>{{ $t('pages_pay_351', [$t('pages_pay_360')]) }}</template>
                      <a-button size="small" type="link">{{ $t('pages_pay_346') }}</a-button>
                    </a-tooltip>
                  </a-space>
                </template>
              </template>
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="detail" :tab="$t('pages_pay_354')">
          <div class="search-form ant-round-form">
            <div class="part flex-align">
              <span class="part-label">{{ $t('pages_pay_164') }}: </span>
              <a-input
                v-model:value="searchDQuery.applyNo"
                allow-clear
                @pressEnter="loadSettlesList(1)"
                @blur="loadDetails(1)"
              />
            </div>
            <div class="part flex-align">
              <span class="part-label">{{ $t('pages_pay_355') }}: </span>
              <a-select
                v-model:value="searchDQuery.busiType"
                allow-clear
                @change="loadDetails(1)"
              >
                <a-select-option
                  v-for="opt in busiTypeOpts"
                  :key="opt.code"
                  :value="opt.code"
                >{{ opt.name }}</a-select-option>
              </a-select>
            </div>
          </div>
          <div class="table-part">
            <a-table
              :scroll="{ x: 800 }"
              :loading="loading"
              :pagination="false"
              :custom-header-row="() => { return {class: 'my-table-tr'}}"
              :columns="columnsD"
              :data-source="details"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
      <table-page
        :total="total"
        :page-opts="activeKey === 'list' ? searchLQuery : searchDQuery"
        @update-page="updatePage"
      />
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import { reactive, toRefs, watch, computed } from 'vue'
import { _pay } from '@/api'
import { getAttrStatusList, getTradeStatusName, parseMoney } from '@/util'
import i18n from '@/locale'
import moment from 'moment'
export default {
  name: 'RmbAccount',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage
  },
  setup () {
    const state = reactive({
      activeKey: '',
      total: 0,
      loading: true,
      exportLoading: false,
      searchLQuery: {
        pageNum: 1,
        pageSize: 10
      },
      searchDQuery: {
        pageNum: 1,
        pageSize: 10,
        applyNo: null,
        busiType: null
      },
      acctList: [],
      details: [],
      columnsL: [
        { key: 'subject', dataIndex: 'subject', title: i18n.global.t('pages_pay_347'), width: 150, ellipsis: true },
        { key: 'accountNo', dataIndex: 'accountNo', title: i18n.global.t('pages_pay_348'), width: 180, ellipsis: true },
        { key: 'openingTime', dataIndex: 'openingTime', title: i18n.global.t('pages_pay_349'), width: 180 },
        { key: 'balance', dataIndex: 'balanceText', title: i18n.global.t('pages_pay_350'), width: 180 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 280 : 220 }
      ],
      columnsD: [
        { key: 'flowNo', dataIndex: 'flowNo', title: i18n.global.t('pages_pay_164'), width: 150, ellipsis: true },
        { key: 'busiType', dataIndex: 'busiTypeText', title: i18n.global.t('pages_pay_355'), width: 150 },
        { key: 'createTime', dataIndex: 'createTime', title: i18n.global.t('pages_pay_356'), width: 180 },
        { key: 'inAmt', dataIndex: 'inAmtText', title: i18n.global.t('pages_pay_357'), width: 180 },
        { key: 'outAmt', dataIndex: 'outAmtText', title: i18n.global.t('pages_pay_358'), width: 180 },
        { key: 'currAvaAmt', dataIndex: 'currAvaAmtText', title: i18n.global.t('pages_pay_359'), width: 180 }
      ]
    })

    state.busiTypeOpts = computed(() => {
      return getAttrStatusList('rmbAcctBusnType')
    })

    watch(() => state.activeKey,
      (value) => {
        if (value === 'list') loadAcctList()
        else loadDetails()
      })

    const updatePage = (pageNum, pageSize) => {
      const attr = state.activeKey === 'list' ? 'searchLQuery' : 'searchDQuery'
      state[attr]['pageNum'] = pageNum
      state[attr]['pageSize'] = pageSize
      if (state.activeKey === 'list') loadAcctList()
      else loadDetails()
    }

    const handleExport = async () => {
      state.exportLoading = true
      await _pay.exportRmbAcctModify(state.searchDQuery)
      state.exportLoading = false
    }

    const loadAcctList = async () => {
      state.loading = true
      const res = await _pay.queryRmbAccount(state.searchLQuery)
      if (res.data.succ) {
        state.acctList = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            balanceText: parseMoney(item.balance)
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
    }

    const loadDetails = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchDQuery.pageNum = pageNum
      const res = await _pay.queryRmbAcctModify(state.searchDQuery)
      if (res.data.succ) {
        state.details = res.data.data.results.map(item => {
          let inAmtText = '-'
          let outAmtText = '-'
          if (['T101', 'T201'].includes(item.tranType)) inAmtText = parseMoney(item.modifyAmt)
          if (['T102', 'T202'].includes(item.tranType)) outAmtText = parseMoney(item.modifyAmt)
          return Object.assign({}, item, {
            inAmtText,
            outAmtText,
            currAvaAmtText: parseMoney(item.currAvaAmt),
            busiTypeText: getTradeStatusName(item.busiType, 'rmbAcctBusnType'),
            createTime: moment(item.createTime * 1000).format('YYYY-MM-DD HH:mm:ss')
          })
        })
        state.total = res.data.data.totalRecord
      }
      state.loading = false
    }

    state.activeKey = 'list'

    return {
      updatePage,
      handleExport,
      loadDetails,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#rmbAccount {
  .common-card-body {
    padding: 8px 0;
    .header-tabs:deep(.ant-tabs-nav) {
      padding: 0 18px;
    }
    .search-form {
      margin-top: 8px;
      .part-label {
        width: 80px;
      }
    }
  }
}
</style>

